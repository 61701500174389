<template>
  <div
    data-el="ui-quantity-box"
    :class="containerClass"
  >
    <component
      :is="props.label ? 'label' : 'div'"
      class="relative flex items-center justify-center rounded-lg bg-transparent"
      :class="[{ 'mb-2': props.enableInfo }]"
    >
      <button
        class="px-1 outline-none"
        :class="[
          isDecrementButtonDisabled ? 'cursor-default' : 'cursor-pointer',
        ]"
        type="button"
        :disabled="isDecrementButtonDisabled"
        @click="() => decrement()"
      >
        <span
          class="m-auto text-xl font-thin leading-5 text-blue-100"
          :class="{
            'transition-colors hover:text-blue-150': !isDecrementButtonDisabled,
          }"
        >−</span>
      </button>

      <UiInput
        :id="props.id"
        v-bind="$attrs"
        v-model="internalValue"
        type="number"
        class="flex max-h-7 w-full appearance-none items-center rounded-full border border-blue-100 bg-white !px-3 !py-2 text-center text-primary outline-none hover:text-black focus:text-black focus:outline-none"
        :name="props.name"
        :container-class="inputContainerClass"
        @input="handleInput"
        @change="handleChange"
      />

      <button
        class="bg-transparent px-1"
        :class="[
          isIncrementButtonDisabled ? 'cursor-default' : 'cursor-pointer',
        ]"
        type="button"
        :disabled="isIncrementButtonDisabled"
        @click="() => increment()"
      >
        <span
          class="m-auto text-xl font-thin leading-5 text-blue-100"
          :class="{
            'transition-colors hover:text-blue-150': !isIncrementButtonDisabled,
          }"
        >+</span>
      </button>
      <slot
        name="label"
        v-bind="{ label: props.label || null }"
      >
        {{ label }}
      </slot>
    </component>
    <slot
      v-if="props.enableInfo && props.labels"
      name="info"
      v-bind="{ min: props.min, max: props.max, interval: props.step }"
    >
      <p class="text-xs text-primary">
        {{
          `${labels?.min}: ${props.min} | ${labels?.interval}: ${props.step}`
        }}
      </p>
    </slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import UiInput from '../UiForm/UiInput/UiInput.vue'
import type { UiQuantityBoxProps } from './UiQuantityBox.types'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<UiQuantityBoxProps>(), {
  labels: null,
  id: 'ui-quantity-box',
  name: 'ui-quantity-box',
  label: '',
  modelValue: 0,
  min: -Number.MAX_VALUE,
  max: Number.MAX_VALUE,
  step: 1,
  enableInfo: false,
  containerClass: () => [],
  inputContainerClass: () => [],
})

const emit = defineEmits<{
  (e: 'click:decrement', value: number): void
  (e: 'click:increment', value: number): void
  (e: 'update:model-value', value: number): void
  (e: 'change', value: number): void
  (e: 'input', value: number, finalValue: number): void
}>()

function calculateFinalInputValue(value: number, saveInternalValue = false) {
  const output
    = Math.round(calculateOutputValue(Number(value)) / props.step) * props.step

  if (saveInternalValue) {
    output >= props.max
      ? (internalValue.value = props.max)
      : (internalValue.value = output)
  }

  return output >= props.max ? props.max : output
}

function handleInput(value: string | number) {
  const numericValue = Number(value)
  const valueHolder = Number.isNaN(numericValue) || value == null || numericValue === 0 ? props.min : numericValue
  emit('update:model-value', valueHolder)
  emit('input', valueHolder, calculateFinalInputValue(valueHolder))
}

function handleChange(value: string | number) {
  emit('change', calculateFinalInputValue(Number(value), true))
}

const isDecrementButtonDisabled = computed(
  () => internalValue.value <= props.min,
)

const isIncrementButtonDisabled = computed(
  () => internalValue.value >= props.max,
)

const internalValue = ref(props.modelValue)
watch(() => props.modelValue, (newValue) => {
  internalValue.value = newValue
})

function decrement(triggerEvents = true) {
  if (!internalValue.value) {
    return
  }

  const value = calculateOutputValue(internalValue.value)

  value <= props.min
    ? (internalValue.value = props.min)
    : (internalValue.value = value - props.step)

  if (triggerEvents) {
    emit('click:decrement', internalValue.value)
    emit('update:model-value', internalValue.value)
    emit('change', internalValue.value)
  }
}

function increment(triggerEvents = true) {
  const value = calculateOutputValue(internalValue.value)

  value >= props.max
    ? (internalValue.value = props.max)
    : (internalValue.value = value + props.step)

  if (triggerEvents) {
    emit('click:increment', internalValue.value)
    emit('update:model-value', internalValue.value)
    emit('change', internalValue.value)
  }
}

function calculateOutputValue(value: number) {
  if (Number.isNaN(value) || !value) { return props.min }

  return Math.round(Math.max(Math.min(props.max, value), props.min))
}

function reset() {
  internalValue.value = props.modelValue
}

defineExpose({
  calculateFinalInputValue,
  increment,
  decrement,
  reset,
})
</script>
